import App from '@/App';
import Main from '@/components/Main';
import Minisite from '@/components/Minisite';
import PanelCompetition from '@/components/PanelCompetition';
import PanelCompetitionSetting from '@/components/PanelCompetitionSetting';
import RouterView from '@/components/RouterView';
import User from '@/components/User';
import AuthenticationLogin from '@/views/AuthenticationLogin';
import AuthenticationRegister from '@/views/AuthenticationRegister';
import AuthenticationReset from '@/views/AuthenticationReset';
import AuthenticationResetConfirmation from '@/views/AuthenticationResetConfirmation';
import ErrorNotFound from '@/views/ErrorNotFound';
import MainLanding from '@/views/MainLanding';
import MainList from '@/views/MainList';
import MainListMap from '@/views/MainListMap';
import MinisiteContact from '@/views/MinisiteContact';
import MinisiteDeclareResult from '@/views/MinisiteDeclareResult';
import MinisiteLanding from '@/views/MinisiteLanding';
import MinisiteList from '@/views/MinisiteList';
import MinisitePaylink from '@/views/MinisitePaylink';
import MinisitePaylinkConfirmation from '@/views/MinisitePaylinkConfirmation';
import MinisiteResults from '@/views/MinisiteResults';
import MinisiteResultsPickClassification from '@/views/MinisiteResultsPickClassification';
import PanelCompetitionMap from '@/views/PanelCompetitionMap';
import PanelCompetitionSettingStoper from '@/views/PanelCompetitionSettingStoper';
import PanelCompetitionStoper from '@/views/PanelCompetitionStoper';
import PanelGate from '@/views/PanelGate';
import UserCompetitionsFuture from '@/views/UserCompetitionsFuture';
import UserCompetitionsPast from '@/views/UserCompetitionsPast';
import UserCompetitionsProposed from '@/views/UserCompetitionsProposed';
import UserEditInsurance from '@/views/UserEditInsurance';
import UserEditProfile from '@/views/UserEditProfile';
import UserMobileMore from '@/views/UserMobileMore';
import UserNotifications  from '@/views/UserNotifications';
import UserMessages  from '@/views/UserMessages';
import UserFamilyChildren from '@/views/UserFamilyChildren';
import UserFamilyChildrenAdd from '@/views/UserFamilyChildrenAdd';
import UserFamilyChildrenEdit from '@/views/UserFamilyChildrenEdit';
import UserFamilyParents from '@/views/UserFamilyParents';
import UserInsurancePolicies from '@/views/UserInsurancePolicies';
import MainSiteOperator from '@/views/MainSiteOperator';
import MainSiteOrganizer from '@/views/MainSiteOrganizer';
import transition from '@/router/guards/transition';
import MinisiteTransferFiles from '@/views/MinisiteTransferFiles';
import MinisiteTransferConfirmation from '@/views/MinisiteTransferConfirmation';
import MinisiteDeclareResultConfirmation from '@/views/MinisiteDeclareResultConfirmation';
import AuthenticationChooseProfile from '@/views/AuthenticationChooseProfile';
import PanelOrganizer from '@/views/PanelOrganizer';
import PanelOperator from '@/views/PanelOperator';
import PanelAdmin from '@/views/PanelAdmin';
import PanelUsersList from '@/views/PanelUsersList';
import UserChangePassword from '@/views/UserChangePassword';
import LogoutUser from '@/views/LogoutUser';
import AuthenticationCreateOrganizer from '@/views/AuthenticationCreateOrganizer';
import PanelCompetitionDashboard from '@/views/PanelCompetitionDashboard';
import PanelCompetitionCreate from '@/views/PanelCompetitionCreate';
import PanelCompetitionEdit from '@/views/PanelCompetitionEdit';
import PanelCompetitionResult from '@/views/PanelCompetitionResult';
import PanelCompetitionResultsLive from '@/views/PanelCompetitionResultsLive';
import PanelCompetitionResultsLiveAll from '@/views/PanelCompetitionResultsLiveAll';
import PanelCompetitionPaymentResult from '@/views/PanelCompetitionPaymentResult';
import PanelCompetitionSettingStoperMainFullscreen from '@/views/PanelCompetitionSettingStoperMainFullscreen';
import PanelCompetitionList from '@/views/PanelCompetitionList';
import PanelCompetitionInsurance from '@/views/PanelCompetitionInsurance';
import PanelCompetitionSettingRegulation from '@/views/PanelCompetitionSettingRegulation';
import PanelProvisionListAdmin from '@/views/PanelProvisionListAdmin';
import PanelProvisionList from '@/views/PanelProvisionList';
import PanelCompetitionClassifications from '@/views/PanelCompetitionClassifications';
import PanelAccountSettings from '@/views/PanelAccountSettings'
import PanelAccountSettingsPayments from '@/views/PanelAccountSettingsPayments'

const routes = [
    {
        // Main.
        name: 'main',
        path: 'main',
        component: Main,
        meta: {
            layout: 'front',
        },
        children: [
            {
                // Main landing.
                name: 'main-landing',
                path: 'landing',
                component: MainLanding,
                alias: '/',
            },
            {
                // Main list.
                name: 'main-list',
                path: 'list',
                beforeEnter: transition,
                component: MainList,
                alias: '/lista-zawodow',
            },
            {
                // Main list.
                name: 'main-list-map',
                path: 'list-map',
                beforeEnter: transition,
                component: MainListMap,
                alias: '/lista-zawodow-mapa',
            },
        ],
        alias: '/', // required for priority over error not found
    },
    {
        name: 'edit-credentials',
        path: 'edit-credentials',
        component: UserChangePassword,
        meta: {
            layout: 'front',
            userRole: ['user', 'organizer', 'operator', 'admin'],
        },
        alias: '/edycja-uwierzytelnienia',
    },
    {
        // Main site operator.
        name: 'main-site-operator',
        path: ':name-op:id(\\d+)',
        component: MainSiteOperator,
        meta: {
            layout: 'front',
        },
        alias: '/:name-op:id(\\d+)',
    },
    {
        // Main site organizer.
        name: 'main-site-organizer',
        path: ':name-or:id(\\d+)',
        component: MainSiteOrganizer,
        meta: {
            layout: 'front',
        },
        alias: '/:name-or:id(\\d+)',
    },
    {
        // Timing offer.
        name: 'timing-offer',
        path: 'timing-offer',
        meta: {
            isLegacyRoute: true,
        },
        alias: '/oferta-pomiaru',
    },
    {
        // Minisite.
        name: 'minisite',
        path: 'minisite',
        component: Minisite,
        meta: {
            layout: 'front',
        },
        children: [
            {
                // Minisite landing.
                name: 'minisite-landing',
                path: 'landing/:id(\\d+)',
                component: MinisiteLanding,
                alias: '/:name-v:id(\\d+)',
            },
            {
                // Minisite contact.
                name: 'minisite-contact',
                path: ':name-vc:id(\\d+)',
                component: MinisiteContact,
                alias: '/:name-vc:id(\\d+)',
            },
            {
                // Minisite start list.
                name: 'minisite-list',
                path: ':name-c:id(\\d+)',
                component: MinisiteList,
                alias: '/:name-c:id(\\d+)',
            },
            {
                // Minisite paylink.
                name: 'minisite-paylink',
                path: ':name-cp:playerId-cv:id',
                component: MinisitePaylink,
                alias: '/:name-cp:playerId-cv:id',
            },
            {
                // Minisite paylink confirmation.
                name: 'minisite-paylink-confirmation',
                path: ':name-cg:id',
                component: MinisitePaylinkConfirmation,
                alias: '/:name-cg:id',
            },

            {
                // Minisite results pick classification
                name: 'minisite-results-pick-classification',
                path: ':name-cc:id(\\d+)',
                component: MinisiteResultsPickClassification,
                alias: '/:name-cc:id(\\d+)',
            },
            {
                // Minisite results live.
                name: 'minisite-results-live',
                path: ':name-c:id(\\d+)',
                meta: {
                    isLegacyRoute: true,
                },
                alias: '/:name-c:id(\\d+)',
            },
            {
                // Minisite results.
                name: 'minisite-results',
                path: ':name-cc:id(\\d+)-ccx:classificationId(\\d+)',
                component: MinisiteResults,
                alias: '/:name-cc:id(\\d+)-ccx:classificationId(\\d+)',
            },
            {
                // 
                name: 'minisite-registered',
                path: ':name-xyz:id(\\d+)',
                meta: {
                    isLegacyRoute: true,
                },
                alias: '/:name-xyz:id(\\d+)',
            },
            {
                // Minisite declare result.
                name: 'minisite-declare-result',
                path: 'declare/:token',
                component: MinisiteDeclareResult,
                alias: '/dodaj-swoj-wynik/:token',
            },
            {
                name: 'minisite-declare-result-confirmation',
                path: 'declare/:playerId/confirmation/:isTimeCompetition',
                component: MinisiteDeclareResultConfirmation,
                alias:
                    '/potwierdzenie-dodania-wyniku/:playerId/:isTimeCompetition',
            },
            {
                // Minisite transfer files.
                name: 'minisite-transfer-files',
                path: 'transfer/:token',
                component: MinisiteTransferFiles,
                alias: '/dodaj-pliki/:token',
            },
            {
                // Minisite transfer confirmation.
                name: 'minisite-transfer-confirmation',
                path: 'transfer/:id/confirmation',
                component: MinisiteTransferConfirmation,
                alias: '/dodaj-pliki/:id/potwierdzenie',
            },
            {
                // Minisite register.
                name: 'minisite-register',
                path: ':name-r:id(\\d+)',
                meta: {
                    isLegacyRoute: true,
                },
                alias: '/:name-r:id(\\d+)',
            },
            {
                // Minisite pay.
                name: 'minisite-pay',
                path: ':name-jk:id(\\d+)-p:token(\\w+)',
                meta: {
                    isLegacyRoute: true,
                },
                alias: '/:name-jk:id(\\d+)-p:token(\\w+)',
            },
            {
                // Minisite edit.
                name: 'minisite-edit',
                path: ':name-r:id(\\d+)-p:token(\\w+)',
                meta: {
                    isLegacyRoute: true,
                },
                alias: '/:name-r:id(\\d+)-p:token(\\w+)',
            },
            {
                // Minisite shop.
                name: 'minisite-shop',
                path: ':name-r:id(\\d+)-p:token(\\w+)',
                meta: {
                    isLegacyRoute: true,
                },
                alias: '/:name-r:id(\\d+)-p:token(\\w+)',
            },
            {
                // Minisite transfer.
                name: 'minisite-transfer',
                path: ':name-t:id(\\d+)-p:token(\\w+)',
                meta: {
                    isLegacyRoute: true,
                },
                alias: '/:name-t:id(\\d+)-p:token(\\w+)',
            },
        ],
    },
    {
        // Authentication.
        name: 'authentication',
        path: 'authentication',
        component: RouterView,
        meta: {
            layout: 'front',
        },
        children: [
            {
                // Authentication login.
                name: 'authentication-login',
                path: 'login',
                component: AuthenticationLogin,
                alias: '/logowanie',
            },
            {
                // Authentication register.
                name: 'authentication-register',
                path: 'register',
                component: AuthenticationRegister,
                alias: '/zaloz-nowe-konto',
            },
            {
                // Authentication reset.
                name: 'authentication-reset',
                path: 'reset',
                component: AuthenticationReset,
                meta: {
                    userRole: 'anonymous',
                },
                alias: '/reset-hasla',
            },
            {
                // Authentication reset confirmation.
                name: 'authentication-reset-confirmation',
                path: 'confirmation',
                component: AuthenticationResetConfirmation,
                meta: {
                    from: 'authentication-reset',
                    userRole: 'anonymous',
                },
                alias: '/reset-hasla-potwierdzenie',
            },
            {
                // Authentication choose profile.
                name: 'authentication-choose-profile',
                path: 'choose-profile',
                component: AuthenticationChooseProfile,
                alias: '/wybor-profilu',
            },
            {
                // Authentication create organizer.
                name: 'authentication-create-organizer',
                path: 'create-organizer',
                component: AuthenticationCreateOrganizer,
                alias: '/tworzenie-profilu-organizatora',
            },
        ],
    },
    {
        // User.
        name: 'user',
        path: 'user',
        component: User,
        meta: {
            layout: 'front',
            userRole: ['user'],
        },
        children: [
            {
                // User edit.
                name: 'user-edit',
                path: 'edit',
                component: RouterView,
                children: [
                    {
                        // User edit profile.
                        name: 'user-edit-profile',
                        path: 'profile',
                        component: UserEditProfile,
                        alias: '/edytuj-dane-osobowe',
                    },
                    {
                        // User edit insurance.
                        name: 'user-edit-insurance',
                        path: 'insurance',
                        component: UserEditInsurance,
                        alias: '/edytuj-dane-dla-ubezpieczenia',
                    },
                ],
            },
            {
                // User competitions.
                name: 'user-competitions',
                path: 'competition',
                component: RouterView,
                children: [
                    {
                        // User competitions future.
                        name: 'user-competitions-future',
                        path: 'future',
                        component: UserCompetitionsFuture,
                        alias: '/twoje-nadchodzace-zawody',
                    },
                    {
                        // User competitions past.
                        name: 'user-competitions-past',
                        path: 'past',
                        component: UserCompetitionsPast,
                        alias: '/twoje-minione-zawody',
                    },
                    {
                        // User competitions proposed.
                        name: 'user-competitions-proposed',
                        path: 'proposed',
                        component: UserCompetitionsProposed,
                        alias: '/twoje-proponowane-zawody',
                    },
                ],
            },
            {
                // User insurance.
                name: 'user-insurance',
                path: 'insurance',
                component: RouterView,
                children: [
                    {
                        // User insurance policies.
                        name: 'user-insurance-policies',
                        path: 'policies',
                        component: UserInsurancePolicies,
                        alias: '/ubezpieczenia',
                    },
                ],
            },
            {
                // User family.
                name: 'user-family',
                path: 'family',
                component: RouterView,
                children: [
                    {
                        // User family parents.
                        name: 'user-family-parents',
                        path: 'parents',
                        component: UserFamilyParents,
                        alias: '/kto-moze-mnie-zapisywac',
                    },
                    {
                        // User family children.
                        path: 'children',
                        component: RouterView,
                        children: [
                            {
                                // User family children edit.
                                name: 'user-family-children-edit',
                                path: 'edit/:id',
                                component: UserFamilyChildrenEdit,
                                alias: '/edytuj-dane-dziecka/:id',
                            },
                            {
                                // User family children add.
                                name: 'user-family-children-add',
                                path: 'add',
                                component: UserFamilyChildrenAdd,
                                alias: '/dodaj-osobe',
                            },
                            {
                                // User family children.
                                name: 'user-family-children',
                                path: '',
                                component: UserFamilyChildren,
                                alias: '/zarzadzaj-rodzina',
                            },
                        ],
                    },
                ],
            },
            {
                // User More.
                name: 'user-more',
                path: 'more',
                component: UserMobileMore,
                alias: '/dane-osobowe-wiecej'
            },            
            {
                // User More.
                name: 'user-notifications',
                path: 'notifications ',
                component: UserNotifications ,
                alias: '/powiadomienia'
            },
            {
                // User More.
                name: 'user-messages',
                path: 'messages ',
                component: UserMessages,
                alias: '/wiadomosci'
            }            
        ],
    },
    {
        // Panel.
        name: 'panel',
        path: 'panel',
        component: RouterView,
        meta: {
            layout: 'back',
            userRole: ['admin', 'organizer', 'operator'],
        },
        children: [
            // Panel admin.
            {
                name: 'panel-admin',
                path: 'admin',
                component: PanelAdmin,
                alias: '/panel-administratora',
                meta: {
                    userRole: ['admin'],
                },
            },
            // Panel provision admin.
            {
                name: 'panel-provision-admin',
                path: 'provision-admin',
                component: PanelProvisionListAdmin,
                alias: '/panel-platnosci',
                meta: {
                    userRole: ['admin'],
                },
            },
            // Panel provision.
            {
                name: 'panel-provision',
                path: 'provision',
                component: PanelProvisionList,
                alias: '/moje-oplaty',
                meta: {
                    userRole: ['admin', 'operator', 'organizer'],
                },
            },
            // Panel admin.
            {
                name: 'panel-users-list',
                path: 'users',
                component: PanelUsersList,
                alias: '/lista-uzytkownikow',
                meta: {
                    userRole: ['admin'],
                },
            },
            // Panel operator.
            {
                name: 'panel-operator',
                path: 'operator/:operatorId?',
                component: PanelOperator,
                alias: '/panel-operatora/:operatorId?',
                meta: {
                    userRole: ['operator', 'admin'],
                },
            },
            // Panel organizer.
            {
                name: 'panel-organizer',
                path: 'organizer/:organizerId?',
                component: PanelOrganizer,
                alias: '/panel-organizatora/:organizerId?',
            },
            // Panel create competition.
            {
                name: 'panel-create-competition',
                path: 'create-competition-i-:organizerId',
                component: PanelCompetitionCreate,
                alias: '/zorganizuj-nowe-zawody-i-:organizerId',
            },
            {
                // Panel competition.
                name: 'panel-competition',
                path: 'competition',
                component: PanelCompetition,
                meta: {
                    userRole: ['admin', 'organizer', 'operator'],
                },
                children: [
                    {
                        // Panel competition dashboard
                        name: 'panel-competition-dashboard',
                        path: ':name-m:id(\\d+)',
                        component: PanelCompetitionDashboard,
                        alias: '/:name-m:id(\\d+)',
                    },
                    {
                        // Panel competition setting.
                        name: 'panel-competition-setting',
                        path: 'setting',
                        component: PanelCompetitionSetting,
                        children: [
                            {
                                // Panel competition setting info.
                                name: 'panel-competition-setting-info',
                                path: ':name-i:id(\\d+)',
                                component: PanelCompetitionEdit,
                                alias: '/:name-i:id(\\d+)',
                            },
                            {
                                // Panel competition setting classifications.
                                name:
                                    'panel-competition-setting-classifications-new',
                                path: ':name-y-n:id(\\d+)',
                                component: PanelCompetitionClassifications,
                                alias: '/:name-y-n:id(\\d+)',
                            },   
                            {
                                // Panel competition setting classifications.
                                name:
                                    'panel-competition-setting-classifications',
                                path: ':name-y:id(\\d+)',
                                meta: {
                                    isLegacyRoute: true,
                                },
                                alias: '/:name-y:id(\\d+)',
                            },
                            {
                                // Panel competition setting numbers.
                                name: 'panel-competition-setting-numbers',
                                path: ':name-nn:id(\\d+)',
                                meta: {
                                    isLegacyRoute: true,
                                },
                                alias: '/:name-nn:id(\\d+)',
                            },
                            {
                                // Panel competition setting rebates.
                                name: 'panel-competition-setting-rebates',
                                path: ':name-re:id(\\d+)',
                                meta: {
                                    isLegacyRoute: true,
                                },
                                alias: '/:name-re:id(\\d+)',
                            },
                            {
                                // Panel competition setting discount.
                                name: 'panel-competition-setting-discount',
                                path: ':name-di:id(\\d+)',
                                meta: {
                                    isLegacyRoute: true,
                                },
                                alias: '/:name-di:id(\\d+)',
                            },
                            {
                                // Panel competition setting register.
                                name: 'panel-competition-setting-register',
                                path: ':name-k:id(\\d+)',
                                meta: {
                                    isLegacyRoute: true,
                                },
                                alias: '/:name-k:id(\\d+)',
                            },
                            {
                                // Panel competition setting cycle.
                                name: 'panel-competition-setting-cycle',
                                path: ':name-ic:id(\\d+)',
                                meta: {
                                    isLegacyRoute: true,
                                },
                                alias: '/:name-ic:id(\\d+)',
                            },
                            {
                                // Panel competition setting regulation.
                                name: 'panel-competition-setting-regulation',
                                path: ':name-if:id(\\d+)',
                                // component: PanelCompetitionSettingRegulation,
                                meta: {
                                    isLegacyRoute: true,
                                },
                                alias: '/:name-if:id(\\d+)',
                            },
                            {
                                // Panel competition setting statement.
                                name: 'panel-competition-setting-statement',
                                path: ':name-ig:id(\\d+)',
                                meta: {
                                    isLegacyRoute: true,
                                },
                                alias: '/:name-ig:id(\\d+)',
                            },
                            {
                                // Panel competition setting confirmation.
                                name: 'panel-competition-setting-confirmation',
                                path: ':name-ie:id(\\d+)',
                                meta: {
                                    isLegacyRoute: true,
                                },
                                alias: '/:name-ie:id(\\d+)',
                            },
                            {
                                // Panel competition setting layout.
                                name: 'panel-competition-setting-layout',
                                path: ':name-ma:id(\\d+)',
                                meta: {
                                    isLegacyRoute: true,
                                },
                                alias: '/:name-ma:id(\\d+)',
                            },
                            {
                                // Panel competition setting stoper.
                                name: 'panel-competition-setting-stoper',
                                path: ':name-mn:id(\\d+)',
                                component: PanelCompetitionSettingStoper,
                                alias: '/:name-mn:id(\\d+)',
                            },
                        ],
                    },
                    {
                        // Panel competition stoper.
                        name: 'panel-competition-stoper',
                        path: ':name-s:id(\\d+)',
                        component: PanelCompetitionStoper,
                        alias: '/:name-s:id(\\d+)',
                    },
                    {
                        // Panel competition list.
                        name: 'panel-competition-list',
                        path: ':name-l:id(\\d+)',
                        component: PanelCompetitionList,
                        meta: {
                            isLegacyRoute: true,
                        },
                        alias: '/:name-l:id(\\d+)',
                    },
                    {
                        // Panel competition list new.
                        name: 'panel-competition-list-new',
                        path: ':name-ln:id(\\d+)',
                        component: PanelCompetitionList,
                        alias: '/:name-ln:id(\\d+)',
                    },
                    {
                        // Panel competition result.
                        name: 'panel-competition-result',
                        path: ':name-u:id(\\d+)',
                        component: PanelCompetitionResult,
                        // meta: {
                        //     isLegacyRoute: true,
                        // },
                        alias: '/:name-u:id(\\d+)',
                    },
                    {
                        // Panel competition result.
                        name: 'panel-competition-payment-result',
                        path: ':name-pp:id(\\d+)',
                        component: PanelCompetitionPaymentResult,
                        alias: '/:name-pp:id(\\d+)',
                    },
                    {
                        // Panel competition results live.
                        name: 'panel-competition-results-live',
                        path: ':name-rsl:id(\\d+)-:classificationId(\\d+)',
                        component: PanelCompetitionResultsLive,
                        alias: '/:name-rsl:id(\\d+)-:classificationId(\\d+)',
                    },
                    {
                        // Panel competition results live all.
                        name: 'panel-competition-results-live-all',
                        path: ':name-rsl:id(\\d+)-all',
                        component: PanelCompetitionResultsLiveAll,
                        alias: '/:name-rsl:id(\\d+)-all',
                    },
                    {
                        // Panel competition result.
                        name: 'panel-competition-result-legacy',
                        path: ':name-uu:id(\\d+)',
                        meta: {
                            isLegacyRoute: true,
                        },                    
                        alias: '/:name-uu:id(\\d+)',
                    },
                    {
                        // Panel competition mail.
                        name: 'panel-competition-mail',
                        path: ':name-me:id(\\d+)',
                        meta: {
                            isLegacyRoute: true,
                        },
                        alias: '/:name-me:id(\\d+)',
                    },
                    {
                        // Panel competition mail.
                        name: 'panel-competition-sms',
                        path: ':name-ms:id(\\d+)',
                        meta: {
                            isLegacyRoute: true,
                        },
                        alias: '/:name-ms:id(\\d+)',
                    },
                    {
                        // Panel competition shop.
                        name: 'panel-competition-shop',
                        path: ':name-sa:id(\\d+)',
                        meta: {
                            isLegacyRoute: true,
                        },
                        alias: '/:name-sa:id(\\d+)',
                    },
                    {
                        // Panel competition shop orders.
                        name: 'panel-competition-shop-orders',
                        path: ':name-ss:id(\\d+)',
                        meta: {
                            isLegacyRoute: true,
                        },
                        alias: '/:name-ss:id(\\d+)',
                    },
                    {
                        // Panel competition insurance.
                        name: 'panel-competition-insurance',
                        path: ':name-is:id(\\d+)',
                        component: PanelCompetitionInsurance,
                        // meta: {
                        //     isLegacyRoute: true,
                        // },
                        alias: '/:name-is:id(\\d+)',
                    },
                    {
                        // Panel competition languages.
                        name: 'panel-competition-languages',
                        path: ':name-in_en:id(\\d+)',
                        meta: {
                            isLegacyRoute: true,
                        },
                        alias: '/:name-in_en:id(\\d+)',
                    },
                    {
                        // Panel competition languages.
                        name: 'panel-competition-languages-cz',
                        path: ':name-in_cz:id(\\d+)',
                        meta: {
                            isLegacyRoute: true,
                        },
                        alias: '/:name-in_cz:id(\\d+)',
                    },
                    {
                        // Panel competition languages.
                        name: 'panel-competition-languages-de',
                        path: ':name-in_de:id(\\d+)',
                        meta: {
                            isLegacyRoute: true,
                        },
                        alias: '/:name-in_de:id(\\d+)',
                    },
                    {
                        // Panel competition languages.
                        name: 'panel-competition-languages-fr',
                        path: ':name-in_fr:id(\\d+)',
                        meta: {
                            isLegacyRoute: true,
                        },
                        alias: '/:name-in_fr:id(\\d+)',
                    },
                    {
                        // Panel competition languages.
                        name: 'panel-competition-languages-lt',
                        path: ':name-in_lt:id(\\d+)',
                        meta: {
                            isLegacyRoute: true,
                        },
                        alias: '/:name-in_lt:id(\\d+)',
                    },
                    {
                        // Panel competition map.
                        name: 'panel-competition-map',
                        path: ':name-im:id(\\d+)',
                        component: PanelCompetitionMap,
                        alias: '/:name-im:id(\\d+)',
                    },
                ],
            },
            {
                // Panel competition stoper fullscreen.
                name: 'panel-competition-setting-stoper-fullscreen',
                path: ':name-mnfs:id(\\d+)',
                component: PanelCompetitionSettingStoperMainFullscreen,
                alias: '/:name-mnfs:id(\\d+)',
                meta: {
                    userRole: ['admin', 'organizer', 'operator'],
                },                
            },            
            // {
            //     // Panel list.
            //     name: "panel-landing",
            //     path: "list",
            //     meta: {
            //         isLegacyRoute: true
            //     },
            //     alias: "/organizator-o"
            // },
            {
                // Panel setting.
                name: 'panel-setting-new',
                path: ':name-set-account-new',
                alias: '/:name-set-account-new',
                component: PanelAccountSettings,
                children: [
                    {
                        name: 'panel-setting-new-info-payments',
                        path: '/:name-set-account-new/payments/:id(\\d+)?',
                        component: PanelAccountSettingsPayments,
                    },
                ],
            },
            {
                // Panel setting.
                name: 'panel-setting',
                path: ':name-set-account',
                meta: {
                    isLegacyRoute: true,
                },
                alias: '/:name-set-account',
            },
            {
                name: 'panel-setting-organizer',
                path: ':name-set-ao:id(\\d+)',
                meta: {
                    isLegacyRoute: true,
                },
                alias: '/:name-set-ao:id(\\d+)',
            },
            {
                // Panel gate.
                name: 'panel-gate',
                path: 'gate/:secureId(\\w+)',
                component: PanelGate,
                alias: '/pomiar-:secureId(\\w+)',
            },
        ],
    },
    {
        // Insurance standalone.
        name: 'insurance-standalone',
        path: ':name-ins:id(\\d+)',
        meta: {
            isLegacyRoute: true,
        },
    },
    {
        path: '/logout-user',
        meta: {
            layout: 'front',
        },
        component: LogoutUser,
        alias: '/wyloguj',
    },
    {
        // Fix legacy index redirect.
        path: '/index',
        redirect: {
            name: 'main-landing',
        },
    },
    {
        // Error not found.
        name: 'error-not-found',
        path: '/*',
        meta: {
            layout: 'front',
        },
        component: ErrorNotFound,
    },
];

function configure(subroutes) {
    for (const route of subroutes) {
        if (!route.children?.length) {
            // Pass route params to view props.
            route.props = true;
        }

        configure(route.children ?? []);
    }

    return subroutes;
}

export default [
    {
        // App.
        path: '/',
        component: App,
        props: ({ matched }) => ({
            // Set layout.
            layout: matched.find(({ meta }) => meta?.layout).meta.layout,
        }),
        children: configure(routes),
    },
];
