<template>
  <div class="container mat40">
    <div class="row admin-header">
      <div class="col-12 col-md-4">
        <h1>Ustawienia profilu organizatora</h1>
        <h3>Potrzebujesz pomocy? Zadzwoń</h3>
        <a href="tel:+502678564" class="call-now">502 678 564</a>
      </div>
      <div class="col-12 col-md-8">
        <div class="buttons-wrap">
          <base-button-group
            horizontal
            class="admin-header__views"
          >
            <base-button
              class="admin-header__views-btn"
              btn11
              @click="navigateToSettings()"
            >
              Dane organizatora
            </base-button>
            <base-button
              class="admin-header__views-btn"
              btn11
              @click="navigateToSettings()"
            >
              Moje umowy
            </base-button>
            <base-button
                class="admin-header__views-btn"
                btn11
                :pressed="$route.name === 'panel-setting-new-info-payments'"
                @click="
                    $router.push({
                      name: 'panel-setting-new-info-payments'
                    })
                "
            >
              Płatności
            </base-button>
            <base-button
              class="admin-header__views-btn"
              btn11
              @click="navigateToSettings()"
            >
              Wygląd
            </base-button>
            <base-button
              class="admin-header__views-btn"
              btn11
              @click="navigateToSettings()"
            >
              Zaawansowane
            </base-button>
          </base-button-group>
        </div>
        <router-view />
      </div>  
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    components: {},
    data() {
        return {};
    },
    computed: {
      ...mapGetters(['user', 'organizers']),
    },
    async created() {},
    methods: {
        navigateToSettings() {
          this.$router.push({
              name: 'panel-setting',
              params: { name: this.organizers[this.user.organizer].name },
              alias: true,
          });
        },
    },
};
</script>

<style scoped>
.admin-header h1 { font-size: 24px; margin-bottom: 16px; }
.admin-header h3 { font-size: 18px; margin-bottom: 6px; }
.admin-header .call-now { font-family: 'Bariol Bold';font-size: 36px;}
.admin-header__views-btn {
  width: 100%;
  justify-content: center;
  user-select: none;
}

@media (min-width: 992px) {
  .admin-header h1 {margin-bottom: 45px;}
}
</style>