<template>
  <div v-if="organizer && Object.keys(organizer).length > 0" class="mat40">
    <template v-if="afterSubmitInfo">
      <PanelInfoBox>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="icon/interfaces/info-circle">
            <path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M4 24C4 35.0457 12.9543 44 24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24ZM29 32H26V21C26 20.4477 25.5523 20 25 20H21C20.4477 20 20 20.4477 20 21C20 21.5523 20.4477 22 21 22H24V32H21C20.4477 32 20 32.4477 20 33C20 33.5523 20.4477 34 21 34H29C29.5523 34 30 33.5523 30 33C30 32.4477 29.5523 32 29 32ZM24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42ZM26 15C26 14.4477 25.5523 14 25 14H23C22.4477 14 22 14.4477 22 15V17C22 17.5523 22.4477 18 23 18H25C25.5523 18 26 17.5523 26 17V15Z" fill="#455A64"/>
          </g>
        </svg>
        <b>Aby skończyc rejestracje przejdź do serwisu Przelewy 24: <a :href="afterSubmitResponse.data.link" target="_blank" rel="noopener noreferrer" style="font-weight: bold; text-decoration: underline;">{{ afterSubmitResponse.data.link }}</a></b>
      </PanelInfoBox>
    </template>
    <template v-else>
      <PanelInfoBox>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="icon/interfaces/info-circle">
            <path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M4 24C4 35.0457 12.9543 44 24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24ZM29 32H26V21C26 20.4477 25.5523 20 25 20H21C20.4477 20 20 20.4477 20 21C20 21.5523 20.4477 22 21 22H24V32H21C20.4477 32 20 32.4477 20 33C20 33.5523 20.4477 34 21 34H29C29.5523 34 30 33.5523 30 33C30 32.4477 29.5523 32 29 32ZM24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42ZM26 15C26 14.4477 25.5523 14 25 14H23C22.4477 14 22 14.4477 22 15V17C22 17.5523 22.4477 18 23 18H25C25.5523 18 26 17.5523 26 17V15Z" fill="#455A64"/>
          </g>
        </svg>
        <b>Aby rozpocząć pobieranie opłat online od uczestników, zdecyduj czy wolisz użyć własnego konta w Przelewy 24, czy zlecić pobieranie opłat dostartu.</b>
      </PanelInfoBox>
      <panel-competition-form-box class="panel-form">
        <div class="row">
          <div class="col-12">
            <h2>Pobieranie płatności online od uczestników</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <panel-input-base-radio 
              v-model="form.startlimit"
              name="startlimit"
              :option="{ value: 'st1', title: 'Zlecam pobieranie opłat' }"
            />
            <div class="field-optional">
              <p>6,0% prowizji od pobieranych płatności</p>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <p class="field-helper mal32">Będziemy pobierać opłaty w Twoim imieniu, a <b>po zakończeniu zawodów</b>, zebrane środki przelejemy na wskazana przez Ciebie konto</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <panel-input-base-radio 
              v-model="form.startlimit"
              name="startlimit"
              :option="{ value: 'st2', title: 'Zbieram płatności' }"
            />
            <div class="field-optional">
              <p>3,6% prowizji od pobieranych płatności</p>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <p class="field-helper mal32">Będziesz pobierać opłaty online we własnym zakresie. Możesz użyć istniejącego konta w <b>Przelewy 24</b> lub założyć nowe konto w kolejnym kroku.</p>
          </div>
        </div>
      </panel-competition-form-box>
      <template v-if="form.startlimit === 'st1'">
		<ValidationObserver v-slot="{ handleSubmit }" ref="orderCollectionOfFeesFormObserver" tag="div" style="margin-top: 16px">
			<form :class="{ 'is-waiting': isWaiting }" @submit.prevent="handleSubmit(orderCollectionOfFees)">
			<panel-competition-form-box class="panel-form">
			<div class="row">
				<div class="col-12 col-md-6">
					<ValidationProvider name="collect.bankAccount" rules="required" v-slot="{ errors }" class="scroll-margin">
						<panel-input-text
							v-model="collect.bankAccount"
							label="Numer konta"
							:error.sync="errors[0]"
							hint="Wpisz nazwe"
						/>
					</ValidationProvider>
				</div>
				<div class="col-12 col-md-6">
				<p class="field-helper mal32">Podaj numer konta, na które chcesz otrzymać zebrane od uczestników opłaty. Środki przelejemy po zakończeniu zawodów.</p>
				</div>
			</div>
			</panel-competition-form-box>
			<panel-competition-form-box v-if="organizer.payAgreements" class="panel-form">
				<div class="row">
					<div class="col-12">
					<a :href="`https://dostartu.pl/${organizer.payAgreements}`" target="_blank" rel="noopener noreferrer" style="font-weight: bold; text-decoration: underline;">Zawarta umowa na zlecenie pobierania opłat</a>
					</div>
				</div>
			</panel-competition-form-box>
			<panel-competition-form-box v-else class="panel-form">
			<div class="row">
				<div class="col-12">
				1. Pobierz umowę 
				</div>
			</div>
			<div class="row">
				<div class="col-12">
				<div class="info-box">
					<p>Umowa na zlecenie pobierania opłat przez dostartu</p>
					<a href="https://dostartu.pl/files/panel/umowa_platnosci.pdf" target="_blank" rel="noopener noreferrer" class="info-box-button">Pobierz umowę<i class="fas fa-file-download" /></a>
				</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">2. Wydrukuj i podpisz umowę</div>
			</div>
			<div class="row">
				<div class="col-12">
				3. Wgraj skan podpisanej umowy
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<ValidationProvider name="collect.file" rules="required" v-slot="{ errors }" class="scroll-margin">
						<panel-input-text
							style="display: none"
							label="Numer konta"
							:error.sync="errors[0]"
							hint="Wpisz nazwe"
						/>
							<base-input-file
								v-model="collect.file"
								accept="image/*,.pdf"
								:isMultiple="false"
								converter="base64"
								hint="Wybierz plik"
								:class="{
									'is-error':  errors[0]
								}"
							/>
							<span v-if="errors[0]" class="input-error-alt">Pole wymagane</span>
					</ValidationProvider>
				</div>
			</div>
			</panel-competition-form-box>
		</form>
		</ValidationObserver>
      </template>
      <panel-competition-form-box v-show="form.startlimit === 'st2'"  class="panel-form">
        <h4>Konto <b>Przelewy 24</b></h4>
        <div class="row">
          <div class="col-12 col-md-6">
            <panel-input-base-radio 
              v-model="form.p24"
              name="form.p24"
              :option="{ value: '12', title: 'Posiadam konto w Przelewy24' }"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <panel-input-base-radio 
              v-model="form.p24"
              name="form.p24"
              :option="{ value: '22', title: 'Chcę założyć konto w Przelewy24' }"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <panel-input-base-radio 
              v-model="form.p24"
              name="form.p24"
              :option="{ value: '32', title: 'Posiadam stare konto Dotpay (ważne do 30.09.2024)' }"
            />
          </div>
        </div>
      </panel-competition-form-box>
      <panel-competition-form-box v-if="form.startlimit === 'st2' && form.p24 === '12'"  class="panel-form">
		<ValidationObserver v-slot="{ handleSubmit }" ref="putOrganizerFormObserver">
			<h4>Moje konto przelewy24</h4>
			<div class="row">
			<div class="col-12 col-md-6">
				<form :class="{ 'is-waiting': isWaiting }" @submit.prevent="handleSubmit(putOrganizerSubmit)">
					<ValidationProvider name="p24put.p24Id" rules="required" v-slot="{ errors }" class="scroll-margin">
						<panel-input-text
							v-model="p24put.p24Id"
							label="Numer ID klienta Przelewy24"
							:error.sync="errors.p24Id"
						/>
					</ValidationProvider>
				</form>
			</div>
			<div class="col-12 col-md-6">
				<p class="field-helper mal32">Numer ID klienta znajdziesz w ustawieniach panelu systemu Przelewy 24, w sekcji Konfiguracja urlc.</p>
			</div>
			</div>
		</ValidationObserver>
      </panel-competition-form-box>
      <ValidationObserver v-if="form.startlimit && form.startlimit === 'st2' && form.p24 && form.p24 === '22'" v-slot="{ handleSubmit }" ref="newMerchantFormObserver">
        <form :class="{ 'is-waiting': isWaiting }" @submit.prevent="handleSubmit(newMerchantSubmit)">
            <panel-competition-form-box class="panel-form mat16">
              <h2>Rejestracja konta Przelewy24</h2>
              <div class="row">
                <div class="panel-form col-12 col-md-6">
                  <div class="row">
                    <div class="col-12">
                      <ValidationProvider name="p24form.business_type" rules="required" v-slot="{ errors }" class="scroll-margin">
                        <panel-input-select
                          v-model="p24form.business_type"
                          selectLabel="Rodzaj działalności"
                          :options="p24BusinessType"
                          :hide-selected="false"
                          :error.sync="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                  <div v-if="p24form.business_type && p24form.business_type.value === 1" class="row">
                    <div class="col-12">
                      <ValidationProvider name="p24form.pesel" :rules="{ required: p24form.business_type && p24form.business_type.value === 1 ? true : false }" v-slot="{ errors }" class="scroll-margin">
                        <panel-input-text
                          v-model="p24form.pesel"
                          label="Pesel"
                          :error.sync="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                  <div v-if="p24form.business_type && p24form.business_type.value > 3" class="row">
                    <div class="col-12">
                      <ValidationProvider name="p24form.krs" :rules="{ required: p24form.business_type && p24form.business_type.value > 3 ? true : false, min: 10, max: 10 }" v-slot="{ errors }" class="scroll-margin">
                        <panel-input-text
                          v-model="p24form.krs"
                          type="number"
                          label="KRS"
                          :error.sync="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                  <div v-if="p24form.business_type && p24form.business_type.value !== 1" class="row">
                    <div class="col-12">
                      <ValidationProvider name="p24form.nip" :rules="{ required: p24form.business_type && p24form.business_type.value !== 1 ? true : false }" v-slot="{ errors }" class="scroll-margin">
                        <panel-input-text
                          v-model="p24form.nip"
                          type="number"
                          label="NIP"
                          :error.sync="errors[0]"
                          class="mab8"
                        />
                      </ValidationProvider>
                      <ValidationProvider name="p24form.regon" :rules="{ required: p24form.business_type && p24form.business_type.value !== 1 ? true : false, min: 9, max: 14 }" v-slot="{ errors }" class="scroll-margin">
                        <panel-input-text
                          v-model="p24form.regon"
                          type="number"
                          label="Regon"
                          :error.sync="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <ValidationProvider name="p24form.name" rules="required" v-slot="{ errors }" class="scroll-margin">
                        <panel-input-text
                          v-model="p24form.name"
                          label="Nazwa firmy / organizacji"
                          :error.sync="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <ValidationProvider name="p24form.email" rules="required|email" v-slot="{ errors }" class="scroll-margin">
                        <panel-input-text
                          v-model="p24form.email"
                          label="E-mail"
                          :error.sync="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <ValidationProvider name="p24form.phoneNumber" rules="required|min:9|max:9" v-slot="{ errors }" class="scroll-margin">
                        <panel-input-text
                          v-model="p24form.phoneNumber"
                          type="number"
                          label="Telefon"
                          :error.sync="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <ValidationProvider name="p24form.bankAccount" rules="required" v-slot="{ errors }" class="scroll-margin">
                        <panel-input-text
                          v-model="p24form.bankAccount"
                          type="number"
                          label="Numer konta"
                          :error.sync="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-12">
                      <panel-input-text
                        v-model="p24form.invoice_email"
                        label="Adres e-mail do faktur"
                        :error.sync="errors.invoice_email"
                      />
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-12">
                      <ValidationProvider name="p24form.street" rules="required" v-slot="{ errors }" class="scroll-margin">
                        <panel-input-text
                          v-model="p24form.street"
                          label="Ulica"
                          :error.sync="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <ValidationProvider name="p24form.postCode" rules="required" v-slot="{ errors }" class="scroll-margin">
                        <panel-input-text
                          v-model="p24form.postCode"
                          label="Kod pocztowy"
                          :error.sync="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <ValidationProvider name="p24form.city" rules="required" v-slot="{ errors }" class="scroll-margin">
                        <panel-input-text
                          v-model="p24form.city"
                          label="Miejscowość"
                          :error.sync="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row" style="display: none">
                    <div class="col-12">
                      <ValidationProvider name="p24form.country" rules="required" v-slot="{ errors }" class="scroll-margin">
                        <panel-input-text
                          v-model="p24form.country"
                          label="Kraj"
                          :error.sync="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <p class="field-helper mal32">Poniższe dane wymagane są wyłącznie do zawarcia umowy z operatorem płatności <b>Przelewy24</b>.</p>
                  <p class="field-helper mal32">Dane zostaną bezpośrednio przekazane firmie <b>Przelewy24</b> i nie będą zapisane w systemie DOSTARTU.</p>
                  <p class="field-helper mal32">Prosimy o podanie danych zgodnych z dokumentami rejestracyjnymi Twojej firmy.</p>
                </div>
              </div>
            </panel-competition-form-box>
            <panel-competition-form-box class="panel-form">
              <h4>Osoba reprezentująca firmę / organizację</h4>
              <div class="row">
                <div class="panel-form col-12 col-md-6">
                  <div class="row">
                    <div class="col-12">
                      <panel-input-text
                        v-model="p24form.contactPersonName"
                        label="Imię i nazwisko"
                        :error.sync="errors.name"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <panel-input-text
                        v-model="p24form.contactPersonEmail"
                        label="E-mail"
                        :error.sync="errors.name"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <panel-input-text
                        v-model="p24form.contactPersonPhoneNumber"
                        label="Telefon"
                        :error.sync="errors.name"
                      />
                    </div>
                  </div>
                  <div class="row" style="display: none">
                    <div class="col-12">
                      <panel-input-text
                        v-model="p24form.contactCountry"
                        label="Kraj"
                        :error.sync="errors.name"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <panel-input-text
                        v-model="p24form.contactCity"
                        label="Miasto"
                        :error.sync="errors.name"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <panel-input-text
                        v-model="p24form.contactPostCode"
                        label="Kod pocztowy"
                        :error.sync="errors.name"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <panel-input-text
                        v-model="p24form.contactStreet"
                        label="Ulica"
                        :error.sync="errors.name"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <ValidationProvider name="input_ids_add_1"  :rules="{ required: { allowFalse: false } }"  v-slot="{ errors }" class="scroll-margin">
                        <panel-input-checkbox 
                          v-model="form.input_ids_add_1"
                          :checkbox-value="{ value: 1, text: 'Akceptuje Regulamin korzystania z usług Przelewy24 na platformie DOSTARTU' }" 
                          :name="`input_ids_add_1`"
                          variant="white"
                          :value="0"
                          :error.sync="errors[0]"
                        >
                          Akceptuje Regulamin korzystania z usług Przelewy24 na platformie DOSTARTU
                        </panel-input-checkbox>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <ValidationProvider name="input_ids_add_2" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }" class="scroll-margin">
                        <panel-input-checkbox 
                          v-model="form.input_ids_add_2"
                          :checkbox-value="{ value: 2, text: 'Jestem uprawniony do reprezentowania powyższego podmiotu' }" 
                          :name="`input_ids_add_2`"
                          variant="white"
                          :error.sync="errors[0]"
                        >
                          Jestem uprawniony do reprezentowania powyższego podmiotu
                        </panel-input-checkbox>
                      </ValidationProvider>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-12">
                      <panel-input-checkbox 
                        :checkbox-value="{ value: 3, text: 'Nie jestem osobą zajmującą eksponowane stanowisko polityczne (Osoba zajmująca eksponowane stanowisko polityczne) lub członkiem rodziny Osoby zajmującej eksponowane stanowisko polityczne lub bliskim współpracownikiem takiej osoby' }" 
                        :name="`input_ids_add[3]`"
                        :value="0"
                        variant="white"
                      >
                        Nie jestem osobą zajmującą eksponowane stanowisko polityczne (Osoba zajmująca eksponowane stanowisko polityczne) lub członkiem rodziny Osoby zajmującej eksponowane stanowisko polityczne lub bliskim współpracownikiem takiej osoby
                      </panel-input-checkbox>
                    </div>
                  </div> -->
                </div>
              </div>
            </panel-competition-form-box>
        </form>
      </ValidationObserver>
	  <panel-competition-form-box v-if="form.startlimit === 'st2' && form.p24 === '32'"  class="panel-form">
		<ValidationObserver v-slot="{ handleSubmit }" ref="putOrganizerFormObserver">
			<h4>Podaj numer aktualnie działającego konta Dotpay</h4>
			<div class="row">
			<div class="col-12 col-md-6">
				<form :class="{ 'is-waiting': isWaiting }" @submit.prevent="handleSubmit(putOrganizerSubmit)">
					<ValidationProvider name="dotpayput.dotpayId" rules="required" v-slot="{ errors }" class="scroll-margin">
						<panel-input-text
							v-model="dotpayput.dotpayId"
							label="Numer ID klienta Dotpay"
							:error.sync="errors.dotpayId"
						/>
					</ValidationProvider>
				</form>
			</div>
			<div class="col-12 col-md-6">
				<p class="field-helper mal32">Konto Dotpay działa do 30.09.2024. Warto założyć konto P24 wcześniej a nadal używać Dotpay aż do zakończenia współpracy.</p>
			</div>
			</div>
		</ValidationObserver>
      </panel-competition-form-box>
      <div v-if="form.startlimit === 'st1'" class="col-lg-12 mat16 mab64">
        <div class="row justify-content-end">
          <div class="col-12 col-lg-4">
            <base-button @click="orderCollectionOfFeesSubmit" btn1 short>Zapisz</base-button>
          </div>
        </div>
      </div>
      <div v-if="form.startlimit === 'st2'  && form.p24 === '12'" class="col-lg-12 mat16 mab64">
        <div class="row justify-content-end">
          <div class="col-12 col-lg-4">
            <base-button @click="putOrganizerSubmit" btn1 short>Zapisz</base-button>
          </div>
        </div>
      </div>
      <div v-if="form.startlimit === 'st2'  && form.p24 === '22'" class="col-lg-12 mat16 mab64">
		  <div class="row justify-content-end">
			  <div class="col-12 col-lg-4">
				  <base-button @click="newMerchantSubmit" btn1 short>Zapisz</base-button>
				</div>
			</div>
		</div>
		<div v-if="form.startlimit === 'st2'  && form.p24 === '32'" class="col-lg-12 mat16 mab64">
		  <div class="row justify-content-end">
			<div class="col-12 col-lg-4">
			  <base-button @click="putOrganizerSubmit" btn1 short>Zapisz</base-button>
			</div>
		  </div>
		</div>
    </template>
  </div>
</template>

<script>
import api from '@/api';
import PanelInfoBox from '@/components/PanelInfoBox'
import PanelCompetitionFormBox from '@/components/PanelCompetitionFormBox';
import PanelInputText from '@/components/PanelInputText';
import PanelInputBaseRadio from '@/components/PanelInputBaseRadio';
import PanelInputCheckbox from '@/components/PanelInputCheckbox';
import PanelInputSelect from '@/components/PanelInputSelect';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, min, max, email } from 'vee-validate/dist/rules';
import { mapGetters } from 'vuex';


extend('required', {
  ...required,
  message: 'required_field'
})
extend('min', min);
extend('max', max);
extend('email', email);

export default {
	name: 'PanelAccountSettingsPayments',
	props: {
        id: {
            type: Number,
            default: null,
        },
	},
  components: {
    PanelInfoBox,
    PanelCompetitionFormBox,
    PanelInputText,
    PanelInputBaseRadio,
    PanelInputCheckbox,
    PanelInputSelect,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      afterSubmitInfo: false,
      afterSubmitResponse: null,
      isWaiting: false,
      form: {
		p24: '12',
        startlimit: 'st1',
      },
	  collect: {
		bankAccount: null,
		file: []
	  },
      p24form: {
		country: 'PL',
		contactCountry: 'PL'
	  },
	  p24put: {
		p24Id: null,
		p24On: true
	  },
	  dotpayput: {
		dotpayId: null,
		dotpayOn: true
	  },
	  organizer: null,
      errors: {},
      p24BusinessType: [
        {
          namePl: 'Osoba fizyczna',
          value: 1
        },
        {
          namePl: 'Jednoosobowa działalność gospodarcza(JDG)',
          value: 2
        },
        {
          namePl: 'Spółka cywilna',
          value: 3
        },
        {
          namePl: 'Spółka jawna',
          value: 4
        },
        {
          namePl: 'Spółka komandytowa',
          value: 5
        },
        {
          namePl: 'Spółka komandytowo-akcyjna',
          value: 6
        },
        {
          namePl: 'Spółka akcyjna',
          value: 7
        },
        {
          namePl: 'Spółka z ograniczoną odpowiedzialnością',
          value: 8
        },
        {
          namePl: 'Stowarzyszenie, fundacja, organizacja pożytku publicznego',
          value: 9
        },
        {
          namePl: 'Spółdzielnia',
          value: 10
        },
      ]
    };
  },
  computed: {
        ...mapGetters(['user']),
		organizerId() {
			return this.id || this.user.organizer
		}
    },
	created() {
		this.getOrganizer()
	}, 
  methods: {
	async getOrganizer() {
		try {
			const response = await api.get(`/panel/organizers/${this.organizerId}`);
			this.organizer = response
			if (this.organizer.p24On) {
				if (this.organizer.payAgreements) {
					this.form.startlimit = 'st1'
				} else {
					this.form.startlimit = 'st2'
					this.form.p24 = '12'
				}
			}
			if (this.organizer.dotpayOn) {
				this.form.startlimit = 'st2'
				this.form.p24 = '32'
			}
			this.p24put.p24Id = this.organizer.p24Id
			this.dotpayput.dotpayId = this.organizer.dotpayId
			this.collect.bankAccount = this.organizer.bankAccount
		} catch (e) {
			this.errors = e;
			return;
		}
	},
    async orderCollectionOfFeesSubmit() {
      this.$refs.orderCollectionOfFeesFormObserver.validate().then(success => {
        if (!success) {
          for (const key of Object.keys(this.$refs.orderCollectionOfFeesFormObserver.fields)) {
            if (this.$refs.orderCollectionOfFeesFormObserver.fields[key].invalid) {
              const y = this.$refs.orderCollectionOfFeesFormObserver.refs[key].$el.getBoundingClientRect().top;
              this.$refs.orderCollectionOfFeesFormObserver.refs[key].$el.scrollIntoView({
                top: y,
                behavior: 'smooth',
              });
              return;
            }
          }
        }
        this.orderCollectionOfFees()
      });
    },
    async putOrganizerSubmit() {
      this.$refs.putOrganizerFormObserver.validate().then(success => {
        if (!success) {
          for (const key of Object.keys(this.$refs.putOrganizerFormObserver.fields)) {
            if (this.$refs.putOrganizerFormObserver.fields[key].invalid) {
              const y = this.$refs.putOrganizerFormObserver.refs[key].$el.getBoundingClientRect().top;
              this.$refs.putOrganizerFormObserver.refs[key].$el.scrollIntoView({
                top: y,
                behavior: 'smooth',
              });
              return;
            }
          }
        }
        this.putOrganizer()
      });
    },
    async newMerchantSubmit() {
      this.$refs.newMerchantFormObserver.validate().then(success => {
        if (!success) {
          for (const key of Object.keys(this.$refs.newMerchantFormObserver.fields)) {
            if (this.$refs.newMerchantFormObserver.fields[key].invalid) {
              const y = this.$refs.newMerchantFormObserver.refs[key].$el.getBoundingClientRect().top;
              this.$refs.newMerchantFormObserver.refs[key].$el.scrollIntoView({
                top: y,
                behavior: 'smooth',
              });
              return;
            }
          }
        }
        this.newMerchant()
      });
    },
    async orderCollectionOfFees() {
      this.isWaiting = true

	  const postPayAgreement = api.post(`panel/organizers/${this.organizerId}/files/payAgreement`, this.collect.file[0])
	  const putOrganizer = api.put(`/panel/organizers/${this.organizerId}`, {
		bankAccount: this.collect.bankAccount,
		dotpayOn: false,
		p24On: false
	  });
	  Promise.all([postPayAgreement, putOrganizer])
    },
    async putOrganizer() {
      this.isWaiting = true

      const payload = {
        ...this.p24put
	  }

	  if (this.form.startlimit === 'st2'  && this.form.p24 === '32') {
		delete payload['p24Id']
		delete payload['p24On']
		payload['dotpayId'] = Number(this.dotpayput.dotpayId)
		payload['dotpayOn'] = this.dotpayput.dotpayOn
	  }

      try {
		await api.put(`/panel/organizers/${this.organizerId}`, payload);
      } catch (e) {
        this.errors = e;
        return;
      } finally {
        this.isWaiting = false
      }
    },
    async newMerchant() {
      this.isWaiting = true

      const payload = {
        ...this.p24form,
        businessType: this.p24form.business_type.value,
		partnerUuid: this.organizerId
      }
      delete payload.business_type

      try {
        const response = await api.post(`/p24/create_merchant`, payload);
        if (response && response.code === 200 || response && response.code === 201) {
          this.afterSubmitInfo = true
          this.afterSubmitResponse = response
        } else if (response && response.code === 400) {

		}
      } catch (e) {
        this.errors = e;
        return;
      } finally {
        this.isWaiting = false
      }
    }
  }
}
</script>

<style scoped>
.is-error {
	border: solid 2px var(--color-pinky-red) !important;
}
.input-error-alt {
	color: var(--color-pinky-red);
    font-size: 12px;
}
.panel-form {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.panel-form .row {
  width: 100%;
}
.panel-form .row .col-12 {
  box-sizing: border-box;
}
.panel-form + .panel-form {
  margin-top: 16px;
}
.info-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 4px;
  background: #FFF;
  color: #455A64;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.10);
}

.info-box-button {
  cursor: pointer;
  display: flex;
  min-width: 167px;
  height: 40px;
  padding: 4px 12px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background: #455A64;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.10);
  color: #FFF;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.field-optional {
  padding: 10px 0px 6px 0px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-top: 1px solid var(--color-bluish-grey, #78909C);
  margin-top: 4px;
  margin-left: 24px;
}
.field-helper {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.field-helper + .field-helper {
  margin-top: 16px;
}
.scroll-margin::v-deep .panel-input-error.input-error {
  left: 0;
  bottom: 0;
  padding: 2px 4px;
  position: relative;
}
@media (min-width: 992px) {
  .mal32 {
    margin-left: 32px;
  }
}
</style>
